import "./src/styles/main.css";
import "./src/styles/blog-post.css";

import React from "react";
import Layout from "./src/components/layout";

export const wrapPageElement = ({ element }) => {
  const newElement = React.cloneElement(
    element,
    element.props,
    React.cloneElement(
      element.props.children,
      element.props.children.props,
      React.createElement(
        Layout,
        undefined,
        element.props.children.props.children
      )
    )
  );
  return newElement;
};

export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};
