export const menuItems = {
    en: [
      {link: "/", label: "Home"},
      {link: "/blog", label: "Blog"},
      {link: "/contact", label: "Contact"},
    ],
    it: [
      {link: "/", label: "Home"},
      {link: "/blog", label: "Blog"},
      {link: "/contatti", label: "Contatti"},
    ]
  }